import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import GUI from 'lil-gui'
import gsap from 'gsap'
import { update } from 'three/examples/jsm/libs/tween.module.js'
import { Analytics } from "@vercel/analytics/react"

/**
 * Base
 */
// debug
//const gui = new GUI()


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color( '#d4d4d4' )

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const donutTexture = textureLoader.load('textures/matcaps/2.png')
const textTexture = textureLoader.load('textures/matcaps/12.png')
const gradientTexture = textureLoader.load('textures/groen2.jpg')
donutTexture.colorSpace = THREE.SRGBColorSpace
textTexture.colorSpace = THREE.SRGBColorSpace

console.log(gradientTexture)

/**
 * Fonts
 */
const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/poppins.json',
    (font) =>
    {
// Material
        const material = new THREE.MeshMatcapMaterial({ matcap: textTexture,
            transparent: true,
            opacity: 0,
        })
        

        const materialDonut = new THREE.MeshMatcapMaterial({ matcap: donutTexture,
            transparent: true,
            opacity: 0
        })


        gsap.to(material, {duration: 4, opacity: 1, ease: "power2.inOut"})

        // Text
        const textGeometry = new TextGeometry(
            'Geert van Osch',
            {
                font: font,
                size: 0.5,
                depth: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        
        textGeometry.center()

        const text = new THREE.Mesh(textGeometry, material)
        scene.add(text)

        // Donuts
        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 32, 64)
        // const donutGeometry = new THREE.BoxGeometry(0.4, 0.4, 0.4, 1)

// Toggle donuts

var intervalID = window.setInterval(donutGeneratie, 500);
function donutGeneratie() {
            const donut = new THREE.Mesh(donutGeometry, materialDonut)

            materialDonut.opacity = 0
            gsap.to(materialDonut, {duration: 1.5, opacity: 0.5})

            donut.position.x = (Math.random() - 0.5) * 10
            donut.position.y = (Math.random() - 0.5) * 10
            donut.position.z = (Math.random() - 0.5) * 10
            donut.rotation.x = Math.random() * Math.PI
            donut.rotation.y = Math.random() * Math.PI
            const scale = Math.random()
            donut.scale.set(0, 0, 0)

            scene.add(donut)
            gsap.to(donut.rotation, {
                duration: 1.5,
                x: Math.random(),
                y: Math.random(),
                ease: "back.out"}
            )
            gsap.to(donut.scale,{
                duration: 1.5,
                x: scale,
                y: scale,
                z: scale,
                ease: "back.out"
            })
            gsap.to(donut.scale, {
                delay: 60,
                duration: 0.5,
                x: 0,
                y: 0,
                z: 0,
                ease: "back.in"
            })
            gsap.to(donut.rotation, {
                delay: 60,
                duration: 0.5,
                x: Math.random(),
                y: Math.random(),
                ease: "back.in"}
            )
    }
    }
)
//////////////////////////////////////
// lights

const ambientLight = new THREE.AmbientLight('white', 1)
scene.add(ambientLight)

const pointLight = new THREE.PointLight('white', 50)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
//////////////////////////////////
// Camera

// Base camera
const camera = new THREE.PerspectiveCamera(30, sizes.width / sizes.height, 0.1, 250)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 20
scene.add(camera)

updateCameraZ()

function updateCameraZ() {
    const width = window.innerWidth;
    const minZ = 6; // minimum z value
    const maxZ = 20; // maximum z value
    const minWidth = 400; // minimum window width
    const maxWidth = 1600; // maximum window width

    console.log(camera.position.x)
    // Map window width to camera z position
    const z = minZ + ((maxWidth - width) / (maxWidth - minWidth)) * (maxZ - minZ);
    camera.position.z = Math.max(minZ, Math.min(maxZ, z));
}

window.addEventListener('resize', () => {
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    updateCameraZ(); // Update camera z position based on new window width
    console.log(camera.aspect)
})

// camera animatie
// const tl = gsap.timeline({repeat: -1}); // repeat: -1 for infinite looping
cameraAnimatie()

function cameraAnimatie(){
    gsap.from(camera.position, {
        duration: 4,
        z: 30,
        ease: "power2.inOut" // this turns off easing
    })
}



////////////////////////////////////////////////////
// Controls
 const controls = new OrbitControls(camera, canvas)
 controls.enableDamping = true
 controls.enablePan = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()